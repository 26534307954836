import {inject, Injectable} from "@angular/core";
import {AsyncSubject, BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "../../shared/service/http.service";
import {environment} from "src/environments/environment";
import {Animal} from "src/app/shared/model/animal";
import {FamilyMember} from "src/app/shared/model/family-member";
import {RegisterAnimal} from "src/app/shared/model/register-animal.model";
import {GalleryImage} from "src/app/shared/types/GalleryImage";
import {UploadFile} from "src/app/shared/model/upload-file";
import {UpdateAnimal} from "../../shared/model/update-animal";

@Injectable({
  providedIn: "root",
})
export class AnimalProfileService {
  apiService = inject(ApiService)
  private animalIdSource = new BehaviorSubject("");
  currentAnimalId = this.animalIdSource.asObservable();
  private animalApiRoutes = environment.api.animal;
  private userApiRoutes = environment.api.user;
  private galleryRoutes = environment.api.gallery;
  private dogFamilyTree = new AsyncSubject();

  setDogFamilyTree(dogFamily: FamilyMember) {
    this.dogFamilyTree.next(dogFamily);
    this.dogFamilyTree.complete();
  }

  getDogFamilyTree(): Observable<any> {
    return this.dogFamilyTree.asObservable();
  }

  getAnimal(id: number) {
    return this.apiService.Get<Animal>(this.animalApiRoutes.getSingle, {params: {id}});
  }

  saveAnimalProfile(animal: Animal): Observable<Animal> {
    const queryParams = {model: animal};
    return this.apiService.Post<Animal>(this.animalApiRoutes.update, queryParams);
  }

  registerAnimal(animal: RegisterAnimal): Observable<Animal> {
    return this.apiService.Post<Animal>(this.animalApiRoutes.register, animal);
  }

  updateAnimal(id: number, updatedAnimal: UpdateAnimal): Observable<Animal> {
    return this.apiService.Put<Animal>(this.animalApiRoutes.update, updatedAnimal, {params: {animalId: id}});
  }


  DeleteAnimal(AnimalId: number): Observable<any> {
    return this.apiService.Delete(`${this.animalApiRoutes.delete}/${AnimalId}`);
  }

  updateAnimalProfileImage(animalId: number, uploadFile: UploadFile) {
    return this.apiService.Post<UploadFile>(this.animalApiRoutes.profileImage.update, uploadFile, {params: {animalId: animalId}});
  }

  getUserAnimals(ownerId?: number) {
    return this.apiService.Get<[]>(this.userApiRoutes.animals, {params: {ownerId}})
  }

  getAllAnimals() {
    return this.apiService.Get<[]>(this.animalApiRoutes.getAll)
  }

  getAnimalFamilyTree(id: number) {
    return this.apiService.Get<FamilyMember>(this.animalApiRoutes.getFamilyTree, {params: {id}});
  }

  refreshFamilyTree(id: number) {
    return this.apiService.Get<FamilyMember>(this.animalApiRoutes.refreshFamilyTree, {params: {id}})
  }

  getCrawlerInfo(ssn: string) {
    return this.apiService.Get<Animal>(this.animalApiRoutes.crawl, {params: {ssn}})
  }

  registerDog(registerAnimal: RegisterAnimal): Observable<any> {
    return this.apiService.Post(this.animalApiRoutes.register, registerAnimal);
  }

  getImagesForAnimal(id: number) {
    return this.apiService.Get<GalleryImage[]>(this.galleryRoutes.image.animal.get, {params: {animalId: id}});
  }

  public existAnimalId() {
    return Number(this.currentAnimalId) != 0;
  }
}
